export default () => [
  {
    from: new Date('31 December 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
  {
    from: new Date('03 January 2025 00:00:00 UTC'),
    content: {
      text: 'Move your way with Reaktiv x Oti Mabuse by Tu. ',
      linkText: 'Shop now',
      link: '/browse/brands/reaktiv/c:1043169?tag=tu:propbar-reaktiv',
      colour: 'black',
    },
  },
  {
    from: new Date('13 January 2025 08:00:00 UTC'),
    content: {
      text: '20% off baby clothing and accessories & Get 5x Nectar points shopping with Tu online!',
      linkText: '',
      link: '/list/shop-all-baby-clothing?tag=tu:propbar-nectar-baby-offer',
      colour: 'purple',
    },
  },
  {
    from: new Date('18 January 2025 00:00:00 UTC'),
    content: {
      text: '20% off baby clothing and accessories. Ends 23:59 on 19th January. ',
      linkText: 'Shop here',
      link: '/list/shop-all-baby-clothing?tag=tu:propbar-baby-offer',
      colour: 'black',
    },
  },
  {
    from: new Date('20 January 2025 00:00:00 UTC'),
    content: {
      text: 'New lines added! Move your way with Reaktiv x Oti Mabuse by Tu. Sizes XS-XXL.',
      linkText: '',
      link: '/browse/brands/reaktiv/c:1043169/?tag=tu:propbar-reaktiv',
      colour: 'black',
    },
  },
  {
    from: new Date('27 January 2025 00:00:00 UTC'),
    content: {
      text: 'Stride into the new season with our Must Haves. ',
      linkText: 'Shop here',
      link: '/list/shop-all-tu-must-haves?tag=tu:propar-musthaves',
      colour: 'black',
    },
  },
  // base propbar
  {
    from: new Date('12 February 2025 00:00:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
]
